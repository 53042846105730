import { api } from '@/api'

const appApi = {
  getUsers: async (nextPageToken) => {
    let users = await api.get('app/users', { params: { nextPageToken } })
    .catch((error) => { console.log(`An error occured while getting app users: ${error}`) })

    return users.data
  },
  getNewUsers: async () => {
    let newUsers = await api.get('app/users-new')
    .catch((error) => { console.log(`An error occured while getting new app users: ${error}`) })

    return newUsers.data
  },
  getEvents: async () => {
    let events = await api.get('app/events')
    .catch((error) => { console.log(`An error occured while getting events: ${error}`) })

    return events.data
  },
  getMoreEvents: async () => {
    let events = await api.get('app/more-events')
    .catch((error) => { console.log(`An error occured while getting events: ${error}`) })

    return events.data
  },
  getEventSnapshot: async () => {
    let events = await api.get('app/events-recent')
    .catch((error) => { console.log(`An error occured while getting events: ${error}`) })

    return events.data
  },
  getRecentUsers: async () => {
    let users = await api.get('app/users-recent')
    .catch((error) => { console.log(`An error occured while getting recent users: ${error}`) })

    return users.data
  },
}

export default appApi
