import { api } from '@/api'
import router from '@/router'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

const adminApi = {
  signIn: async (email, password) => {
    const response = await signInWithEmailAndPassword(getAuth(), email, password)
    const user = response.user
    const credentials = await user.getIdTokenResult()
    const isAdmin = credentials.claims.admin

    if (isAdmin) router.push('/dashboard')
    else {
      signOut(getAuth())
      router.go()
    }
  },
  signOut: async () => {
    await signOut(getAuth())

    router.go()
  },
  getUsers: async () => {
    let users = await api.get('admin/users')
    .catch((error) => { console.log(`An error occured while getting admin users: ${error}`) })

    return users.data
  }
}

export default adminApi
