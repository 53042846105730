import LoginView from '../views/LoginView.vue'

export const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Sign In'
    }
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminLayout.vue'),
    meta: { private: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
        meta: {
          title: 'Users',
        }
      },
      {
        path: '/events',
        name: 'events',
        component: () => import(/* webpackChunkName: "events" */ '../views/EventsView.vue'),
        meta: {
          title: 'Events',
        }
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
        meta: {
          title: 'Settings',
        }
      }
    ]
  }

]
