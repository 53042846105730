import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
import { auth } from '@/services/firebase'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // Set project name
  let projectName = 'Flixreel Admin';
  // Retrieve route title
  let title = to.meta.title;
  // If title exists, display title and project name on tab
  if (title) document.title = `${title} | ${projectName}`;
  // Display project name on tab
  else document.title = projectName;

  // Handle private and public route access
  let isAdmin;
  const authenticatedUser = auth.currentUser;
  if (authenticatedUser) {
    const credentials = await auth.currentUser.getIdTokenResult()
    isAdmin = credentials.claims.admin
  }
  const privateRoute = to.matched.some(record => record.meta.private);

  if (privateRoute && (!authenticatedUser || !isAdmin)) next('/');
  else if (!privateRoute && authenticatedUser && isAdmin) next('/dashboard');
  else next();
})

export default router
