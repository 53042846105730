import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/design/main.scss'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/services/firebase'

let app
onAuthStateChanged(auth, async () => {
  if (!app) app = createApp(App).use(router).mount('#app')
})
