import axios from 'axios'
import admin from './endpoints/admin'
import app from './endpoints/app'
import { getAuth } from 'firebase/auth'

/////// Initiate API ///////
let apiURL;
if (process.env.NODE_ENV === 'production') {
  apiURL = 'https://us-central1-flixreel-administration.cloudfunctions.net/app/api'
} else {
  apiURL = 'http://localhost:5001/flixreel-administration/us-central1/app/api'
}

export const api = axios.create({
  baseURL: apiURL
})

api.interceptors.request.use(async config => {
  // Generate Token
  config.headers['X-Flixreel-Access-Token'] = await getAuth().currentUser.getIdToken(true)

  return config
}, error => console.log(error))

// export default api
export default { admin, app }
