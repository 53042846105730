import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// Establish project credentials
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

// Initialize firebase
const firebaseApp = initializeApp(firebaseConfig);
// Auth
export const auth = getAuth(firebaseApp);

// Initialize appcheck
// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6LdLhtQeAAAAADiaP7pa2i7NWWRvV7wTjOplTZ6V'),
//   isTokenAutoRefreshEnabled: true
// });
