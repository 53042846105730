<template>
  <section class="login">
    <form @submit.prevent="handleAccessRequest()" novalidate>
      <h1>Flixreel Admin</h1>
      <input type="email" placeholder="Email" v-model="email">
      <input type="password" placeholder="Password" v-model="password">
      <button type="submit">Login</button>
    </form>
  </section>
</template>

<script>
import api from '@/api'

export default {
  name: 'LoginView',
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    handleAccessRequest() {
      if (this.email.trim() && this.password.trim()) {
        api.admin.signIn(this.email.trim(), this.password.trim())
      }
    }
  }
}
</script>
